import React, { useState, useEffect } from 'react'
//import products from '../products';
import { Row, Col, Alert, Pagination, Nav, Form, Button} from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup';
import Info from '../components/Info'
import Area from '../components/Area'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { useDispatch, useSelector } from 'react-redux'
import { listInfos } from '../actions/InfoActions'
import { Formik }  from 'formik'
import { areaInfos, areaSecondInfos } from '../actions/AreaActions'
import banner from '../static/image/banner.jpeg'

const HomeScreen = ({ match, history }) => {
    const keyword = match.params.keyword
    const pageNumber = match.params.pageNumber || 1
    const areaIdNum = match.params.areaIdNum || 8
    const areaSecondIdNum = match.params.areaSecondIdNum || null

    const dispatch = useDispatch();
    const infoList = useSelector((state) => state.infoList);
    const { loading, error, infos, pageInfo } = infoList;

    const areaList = useSelector((state) => state.areaList);
    const { areaLoading, areaError, areas } = areaList;

    const areaSecondList = useSelector((state) => state.areaSecondList);
    const { areaSecondLoading, areaSecondError, areaSeconds } = areaSecondList;

    const [pageList, setPageList] = useState([])
    const [pageNum, setPageNum] = useState(1)
    const [pageLast, setPageLast] = useState(1)
    const [areaId, setAreaId] = useState(1)
    const [areaSecondId, setAreaSecondId] = useState(1)

    const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

    useEffect(() => {
      console.log("HomeScreen pageNumber="+pageNumber + ",areaIdNum="+areaIdNum+ ",areaSecondIdNum="+areaSecondIdNum);
        let pageParam = { 
            pageNum: pageNumber,
            pageSize: 16
        }
        let infoParam = {
            areaId: areaIdNum,
            areaSecondId: areaSecondIdNum,
            pageParam: pageParam
        }
        setAreaId(areaIdNum);
        setAreaSecondId(areaSecondIdNum);
        dispatch(listInfos(infoParam))
      }, [dispatch, keyword, pageNumber, history])


    useEffect(() => {
      dispatch(areaInfos())
    }, [dispatch, history])

  /**
   * 分页参数
   */
  useEffect(() => {
    if(pageInfo !== null && pageInfo !== undefined) {
      setPageNum(pageInfo.pageNum);
      setPageLast(pageInfo.pages)
      if(pageInfo.pageNum < 5 && pageInfo.pages > 5) {
        //console.log("pageInfo.pageNum <= 5 && pageInfo.lastPage > 5")
        let list = [1,2,3,4,5,pageInfo.pages];
        setPageList(list);
      } else if(pageInfo.pages <= 5){
        //console.log("pageInfo.lastPage <= 5")
        let list = [];
        for(let i=1; i<= pageInfo.pages; i++ ) {
          list[i-1] = i;
        }
        setPageList(list);
      } else if(pageInfo.pageNum >= 5 && pageInfo.pageNum <= pageInfo.pages -2) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  <= pageInfo.lastPage -2")
        let list = [];
        list[0] = pageInfo.pageNum - 2;
        list[1] = pageInfo.pageNum - 1;
        list[2] = pageInfo.pageNum;
        list[3] = pageInfo.pageNum + 1;
        list[4] = pageInfo.pageNum + 2;
        if(pageInfo.pageNum + 2 < pageInfo.pages) {
          list[5] = pageInfo.pages;
        }
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.pages-1) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage -1")
        let list = [];
        list[0] = pageInfo.pageNum - 3;
        list[1] = pageInfo.pageNum - 2;
        list[2] = pageInfo.pageNum - 1;
        list[3] = pageInfo.pageNum;
        list[4] = pageInfo.pageNum + 1;
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.pages) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage")
        let list = [];
        list[0] = pageInfo.pageNum - 4;
        list[1] = pageInfo.pageNum - 3;
        list[2] = pageInfo.pageNum - 2;
        list[3] = pageInfo.pageNum - 1;
        list[4] = pageInfo.pageNum;
        setPageList(list);
      }
    }
  }, [pageInfo])


  /**
   * 分页查询
   * @param {*} pageNum 
   */
    const handlePage= (pageNum) => {
        //console.log("areaId=" + areaId + ",areaSecondId=" + areaSecondId + ",pageNum="+pageNum);
        if(pageNum>= pageLast) {
          pageNum = pageLast
        } else if (pageNum <= 1) {
          pageNum = 1
        }
        let pageParam = { 
            pageNum: pageNum,
            pageSize: 16
        }
        let infoParam = {
            areaId: areaId,
            areaSecondId: areaSecondId,
            pageParam: pageParam
        }
        dispatch(listInfos(infoParam))
      }
    
    const handleOneClick = (areaId) => {
      let pageParam = { 
          pageNum: 1,
          pageSize: 16
      }
      let infoParam = {
          areaId: areaId,
          pageParam: pageParam
      }

      let areaParam = {
        parentId: areaId
      }
      setAreaId(areaId)
      setAreaSecondId(null)
      dispatch(areaSecondInfos(areaParam))
      dispatch(listInfos(infoParam))
    };

    const handleSecondClick = (areaSecondId) => {
      let pageParam = { 
          pageNum: 1,
          pageSize: 16
      }
      let infoParam = {
          areaId: areaId,
          areaSecondId: areaSecondId,
          pageParam: pageParam
      }
      setAreaSecondId(areaSecondId)
      dispatch(listInfos(infoParam))
    };


      /**
   * 表单提交函数
   * @param {*} values 
   */
  const submitHandler = (values) => {
    //console.log("进入");

    dispatch(handlePage(values.pageNum))
  }

  // const imageClick = () => {
  //   window.location.href = 'http://ylcc.yilelive.com/other/2024/03/10000_021325996TianXin_3.23_0309_1407.apk'
  // }

    return (
        <>
            {/* <Row>
                <img src={banner} source={banner} alt="banner" style={{ width: "100%", height: 300, marginBottom: 20 }} onClick={() => imageClick()} />
            </Row> */}

            {areaLoading ? (
                <Loader />
            ) : areaError ? (
                <Message variant='danger'>{areaError}</Message>
            ) : (
                    <Row>
                      <h5>省份:</h5>
                      <Nav variant="pills" activeKey={`${areaId}`}>
                      { 
                          areas && areas.map((area) => (
                              <Nav.Item key={area.id}>
                                <Nav.Link eventKey={area.id} onClick={() => handleOneClick(area.id)}>{area.name}</Nav.Link>
                              </Nav.Item>
                      ))}
                      </Nav>
                    </Row>
            )}

            {areaSecondLoading ? (
                <Loader />
            ) : areaSecondError ? (
                <Message variant='danger'>{areaSecondError}</Message>
            ) : (
                    
                    <Row>
                      <h5>地区:</h5>
                      <Nav variant="pills" activeKey={`${areaSecondId}`}>
                      { 
                          areaSeconds && areaSeconds.map((area) => (
                              <Nav.Item key={area.id}>
                                <Nav.Link eventKey={area.id} onClick={() => handleSecondClick(area.id)}>{area.name}</Nav.Link>
                              </Nav.Item>
                          ))
                      }
                      </Nav>
                    </Row>
            )}


            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                    <Row>
                    { 
                        infos && infos.map((info) => (
                            <Col key={info.id} sm={12} md={6} lg={4} xl={3}>
                                <Info info={info} areaId={areaId||8} areaSecondId={areaSecondId} pageNum={pageNum||1}/>
                            </Col>
                    ))}

                        <Pagination size="md">
                                <Pagination.First onClick={() => handlePage(1)} />
                                <Pagination.Prev onClick={() => handlePage(pageNum-1)}/>
                                {
                                pageList.map((page, index) => (
                                    <Pagination.Item key={index} active={page === pageNum} onClick={() => handlePage(page)}>{page}</Pagination.Item>
                                ))
                                }
                                <Pagination.Next onClick={() => handlePage(pageNum+1)}/>
                                <Pagination.Last onClick={() => handlePage(pageLast)}/>
                                
                                {/* <Formik
                                  onSubmit={(values) => {
                                      submitHandler(values);
                                  }}
                                  initialValues={{
                                    pageNum: '',
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                  }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  <Form.Group controlId='pageNum'>
                                    <Col sm={5}>
                                      <Form.Control size="sm"
                                        type='pageNum'
                                        placeholder='1'
                                        value={values.pageNum}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pageNum}
                                      ></Form.Control>
                                      </Col>
                                  </Form.Group>
                                </Form>
                                )}
                                </Formik> */}
                        </Pagination>

                      
                    </Row>
            )}
        </>
    )
}

export default HomeScreen
